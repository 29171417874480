<vb-auth-layout>

	<div [hidden]="!status.loading">
		<vb-loading-spinner [msg]="'Login_LogoffMessage' | translate" [size]="'large'">
		</vb-loading-spinner>
	</div>

	<div [hidden]="!status.active">
		<div [ngClass]="[authLayoutStyles.centered, authLayoutStyles.logoWrapper]">
			<branding-logo></branding-logo>
		</div>

		<div [ngClass]="authLayoutStyles.authForm">
			<div [ngClass]="authLayoutStyles.heading">
				<h2>{{ 'Login_LoggedOffMessage' | translate }}</h2>
			</div>

			<div [ngClass]="[authLayoutStyles.authMsg, authLayoutStyles.centered]">
				{{ 'Login_LoggedOutMessage' | translate }}
			</div>

			<a class="btn btn-primary theme-accent theme-accent-txt margin-top-30" [ngClass]="authLayoutStyles.authBtn" [href]="loginUrl">{{ 'Login' | translate }}</a>

			<div class="margin-top-20 pull-right">
				<!-- CUSTOM CHANGE -->
				<a href="https://selfreg.video.nd.gov" style="margin-left: 2ex" class="theme-header-txt self-registration-link">(Self Registration)</a>
			</div>

			<a class="btn btn-primary theme-accent theme-accent-txt margin-top-30" [ngClass]="authLayoutStyles.authBtn" href="https://selfreg.video.nd.gov">(Self Registration)</a>
		</div>
	</div>

</vb-auth-layout>
